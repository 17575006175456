<template>
  <div class="main-content" v-if="canAdministrate">
    <RoomEditionModal/>
    <RoomsetDeletionModal/>
    <h1 class="mb-3 text-26">{{ $t("Care Units & Sensor Management") }}</h1>  
    <b-card no-body>
    <b-tabs pills card @>

      <template v-for="rs in $store.state.fleet.roomsetList.filter((p) => p.organizationLabel === $store.state.fleet.currentOrganization  && p.label.charAt(0) !== '_').sort((a,b) => new Date(a.createdAt) - new Date(b.createdAt))">				
            <b-tab lazy :key="rs.oid" :title="rs.label">              
               <RoomsetEdition :roomset="rs" ></RoomsetEdition>
            </b-tab>            
      </template>      
      <template #tabs-end>
          <b-nav-item class="justify-content-end" end="true" role="presentation" @click.prevent="newRoomset" href="#"><b>+</b></b-nav-item>
      </template>
    </b-tabs>
  </b-card>
  </div>
    <div class="main-content" v-else>
        <b-row>
      <b-col>
        <div class="not-found-wrap text-center">
          <h1 class="text-25">
            {{ $t('Access Denied') }}
          </h1>
          <p class="text-36 subheading mb-3">{{ $t("You don't have enough privileges to administrate Care Units and Sensors.")}}</p>
          <p class="mb-5 text-muted text-18">
            {{ $t('You need administration role, or medical administrator role to access this page. If you think that you should access to this page, please contact your administrator or mintt support at ') }} <a href="mailto:support@mintt.care">support@mintt.care</a>
          </p>
          <a class="btn btn-lg btn-primary btn-rounded" href="/" id="gotohome">{{ $t('Go back to home') }}</a
          >
        </div>
      </b-col>
    </b-row>  
  </div>
</template>



<script>

import RoomsetEdition from "@/components/ISA/admin/RoomsetEdition";
import RoomsetDeletionModal from "@/components/ISA/admin/RoomsetDeletionModal";
import RoomEditionModal from "@/components/ISA/admin/RoomEditionModal";
import { AuthService } from "@/services/auth.service";
import { SPService } from "@/services/sp.service";
import { API_URL } from '@/.env'

export default {
  name: "ISARoomsetManager",

  props: {},
  components: {
    RoomEditionModal,
    RoomsetEdition,
    RoomsetDeletionModal
  },
  computed: {
    canAdministrate()  {
      return AuthService.canAdministrate(this.$store.state.user.currentUser.role);
    }
  },
  watch: {
    "$store.state.fleet.currentOrganization": async function (val, oldVal) {
      const choices = this.$store.state.fleet.roomsetList.filter(
        (p) =>
          p.organizationLabel === this.$store.state.fleet.currentOrganization && p.label.charAt(0) !== '_'
      );
      if (choices && choices.length) this.selectedRoomsetOid = choices[0].oid;
    },

    "selectedRoomsetId": function(val, oldVal) {
      this.roomsetSelect(val);
    }
  },
  methods: {
    newRoomset() {
      const choices = this.$store.state.fleet.roomsetList.filter(
        (p) =>
          p.organizationLabel === this.$store.state.fleet.currentOrganization && p.label.charAt(0) !== '_'
      );
      
      let template = {
        licensing: choices[0].licencing,
        authorizedUserOIDList: []  ,      
        featureBedexitEnabled: choices[0].featureBedexitEnabled,
        featureCloseFollowUpEnabled: choices[0].featureCloseFollowUpEnabled,
        featureFallEnabled: choices[0].featureFallEnabled,
        featureMonthlyReportEnabled: choices[0].featureMonthlyReportEnabled,
        featureWeeklyReportEnabled: choices[0].featureWeeklyReportEnabled,
        featureYearlyReportEnabled: choices[0].featureYearlyReportEnabled,              
        location: choices[0].location,   
        organizationLabel: this.$store.state.fleet.currentOrganization,
        roomList: [],
        timezone: choices[0].timezone, 
        weeklyReportDayOfWeek: choices[0].weeklyReportDayOfWeek,
      };
      
      let i = 0;
      let exists = true;
      while(exists) {
        let rsName = this.$t('New Care Unit') + (i > 0 ? (' ' + i) : '')
        let existing = this.$store.state.fleet.roomsetList.filter(
          (p) => p.organizationLabel === this.$store.state.fleet.currentOrganization 
          && p.label === rsName
        );
        if(existing.length > 0) {
          i++;
          exists = true
        } else {
          template.label = rsName;
          exists = false;
        }
      }
      
      this.$dialog
          .confirm(this.$t('Are you sure you need to create a new Care Unit?'), { okText: this.$t('Yes'), cancelText: this.$t('No')} )
          .then(async (dialog) => {            
            const result = await SPService.createRoomset(
              API_URL.url,
              this.$store.state.user.currentUser.jwt,
              template
            );
            
            if (result.status === 200) {
              this.roomsetlabelChanged = false;
              this.roomsetfeatureChanged = false;
              this.$bvToast.toast(this.$t('The roomset has been created with success'), {
                    title: this.$t('Roomset edition'),            
                    solid: true,
                    variant: 'success',
                    duration: 5000
              });
            setTimeout(() => {
                this.$store.dispatch('fleet/shortPoll')
            },300) 
            } else {        
              this.$bvToast.toast(this.$t('Error while updating the roomset') + `\n${result.status}: ${result.statusMessage}`, {
                    title: this.$t('Roomset edition'),            
                    solid: true,
                    variant: 'warning',
                    duration: 5000
              });
            }        
          })
          .catch(function() {
            
          });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
