<template>
  <b-modal hide-footer v-model="showRoomEditor" @hidden="close" :title="$t('ISA Sensor')">
    <div>
      <b-card >
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
          <b-form @submit.stop.prevent="handleSubmit(apply)">
            <validation-provider
              :name="$t('Room Label')"
              :rules="validationRules"
              v-slot="validationContext"
            >
              <b-form-group
                label-cols-sm="3"
                :label="$t('Room label:')"
                label-align-sm="left"
                label-for="roomLabel"
              >
                <b-form-input
                  v-model="displayedLabel"
                  :state="getValidationState(validationContext)"
                  :readonly="labelDisabled"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>
                <b-form-text id="warning-message" v-if="labelDisabled">
                  {{ $t('The sensor is currently being set up. Editing the room label is not permitted at this stage.') }}
                </b-form-text>
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
                label-cols-sm="3"
                :label="$t('Spoken text override in English (phone notification)')"
                label-align-sm="left"                
              >
                <b-form-input
                  v-model="spokenTextOverride_en"                  
                ></b-form-input>                
            </b-form-group>

            <b-form-group
                label-cols-sm="3"
                :label="$t('Spoken text override in French (phone notification)')"
                label-align-sm="left"                
              >
                <b-form-input
                  v-model="spokenTextOverride_fr"                  
                ></b-form-input>                
            </b-form-group>

            <b-form-group
                label-cols-sm="3"
                :label="$t('Spoken text override in Nederlands (phone notification)')"
                label-align-sm="left"                
              >
                <b-form-input
                  v-model="spokenTextOverride_nl"                  
                ></b-form-input>                
            </b-form-group>

              

            <b-button  class="float-left" @click="close">
                {{ $t('Cancel')}}
            </b-button>
            <b-button  :loading="requesting" variant="primary" class="float-right" type="submit">
                {{ $t('Apply')}}
            </b-button>

          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </b-modal>
</template>

<script>
import { SPService } from "@/services/sp.service";
import { API_URL } from '@/.env'
import ROLES from "@/constants/roles";
import { extend } from 'vee-validate';

extend('notUnderscore', {
  params: ['isMinttAdmin', 'message'],
  validate(value, { isMinttAdmin }) {
    if ( !isMinttAdmin ) {
      return !value.startsWith('_');
    } else {
      return true;
    }
  },
  message: (_, values) => values.message
});

export default {
  components: {
    
  },
  data() {
    return {
      requesting: false,
      showRoomEditor: false,
      roomLabel: '',
      spokenTextOverride_fr: '',
      spokenTextOverride_en: '',
      spokenTextOverride_nl: '',
      oid: null,      
      labelDisabled: false,
    };
  },
  computed: {
    options() {
      return this.$store.state.fleet.roomsetList.map(p => {
        return { name: p.label };
      });
    },
    displayedLabel: {
      get() {
        if(!this.isMinttAdmin() && this.roomLabel.startsWith('_') && this.labelDisabled) {
          return this.roomLabel.slice(1);
        }
        return this.roomLabel;
      },
      set(value) {
        this.roomLabel = value;
      }
    },
    validationRules() {
      let rules = {
        required: true,
        min: 1,
        max: 50,
        notUnderscore: {
          isMinttAdmin: this.isMinttAdmin(),
          message: this.$t('Room name cannot start with an underscore')
        }
      };
      return rules;
    },
  },
  watch: {
    "$store.state.user.roomEditorShow": function(val) {
      this.showRoomEditor = val;
      this.loadRoom();

      if(val) {
        const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'roomedit',
          path:  window.location.pathname,
          data: window.location.href,
      }
        
       SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
      }
    },
    "$store.state.user.roomEditorData": function(user) {
      this.loadRoom();
    }
  },
  methods: {    
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    close() {
      this.$store.commit("user/SHOW_ROOMEDITOR", null);
    },
    loadRoom() {
      if (this.$store.state.user.roomEditorData) {
        this.requesting = false;        
        this.oid = this.$store.state.user.roomEditorData.oid;
        this.roomLabel = this.$store.state.user.roomEditorData.label;   
        this.spokenTextOverride_fr = this.$store.state.user.roomEditorData.spokenTextOverride ? this.$store.state.user.roomEditorData.spokenTextOverride.fr : '';
        this.spokenTextOverride_en = this.$store.state.user.roomEditorData.spokenTextOverride ? this.$store.state.user.roomEditorData.spokenTextOverride.en : '';
        this.spokenTextOverride_nl = this.$store.state.user.roomEditorData.spokenTextOverride ? this.$store.state.user.roomEditorData.spokenTextOverride.nl : '';  
        this.labelDisabled = (!this.isMinttAdmin() && this.roomLabel.startsWith('_'));
      }
    },    
    async apply() {
      // here we know that the form is validated
      this.requesting = true
      const room = {
          "oid": this.oid,
          "label": this.roomLabel,
          spokenTextOverride: {
            fr: this.spokenTextOverride_fr,
            en: this.spokenTextOverride_en,
            nl: this.spokenTextOverride_nl,
          }
      }  
      let answer = await SPService.updateRoom(API_URL.url, this.$store.state.user.currentUser.jwt, 
          room       
        );
        await this.$store.commit('fleet/CHANGE_ROOMLABEL', room)
        if(answer.status !==  200) {
          this.$store.dispatch('fleet/shortPoll');          
          this.$bvToast.toast(this.$t('Error during room modification'), {
              title: this.$t('Room edition'),            
              solid: true,
              variant: 'warning',
              duration: 5000
            });
        }
        else          
          this.$bvToast.toast(this.$t('room modified with success'), {
              title: this.$t('Room edition'),            
              solid: true,
              variant: 'success',
              duration: 5000
            });
        this.oid = answer.oid;
      
      this.requesting = false
      // close editor
      this.$store.commit("user/SHOW_ROOMEDITOR", null);

    },
    isMinttAdmin() {
      return this.$store.state.user.currentUser && this.$store.state.user.currentUser.role == ROLES.adminMintt
    }
  },
  mounted() {
    this.$store.commit("user/SHOW_ROOMEDITOR", null);
    this.requesting = false;    
  },
  name: "RoomEditionModal"
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>

<style lang="scss" scoped>
</style>
