<template>
  <div>
    <b-card  v-if="roomset" style="margin: 5px">
      <span style="font-weight: 800">{{$t('Care Unit properties:')}}</span>

      <table class="isafeattab">
        <tr>
          <td><span class="isalabel">{{ $t('Care Unit Name:') }}</span> </td>
          <td>
            <validation-provider
              :rules="validationRules"
              v-slot="validationContext"
              label-for="roomsetName"
              >
              <b-form-group>
                <b-form-input
                  v-model="local__label"
                  :state="getValidationState(validationContext)"
                  @keydown="roomsetlabelChanged = true"
                  @change.native="roomsetlabelChanged = true"
                  ></b-form-input>
                <b-form-invalid-feedback
                  id="input-1-live-feedback"
                  >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </td>
        </tr>


        <tr>
          <td><span class="isalabel">{{ $t('Care Unit Timezone:') }}</span> </td>
          <td>
            <b-row>
              <b-col cols="10">
                <b-select
                  v-model="local__timezone"
                  :options="timezones"
                  @change="roomsetlabelChanged = true"
                  />
              </b-col>
              <b-col cols="2">
                <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISATimeZoneHelp')"/>
              </b-col>
            </b-row>
          </td>
        </tr>
        <tr>
          <td>

            <b-button variant="secondary" float-right :styled="true" @click="deleteRoomset" :disabled="roomset.roomList && roomset.roomList.length > 0">
              {{ $t('Delete Care Unit')}}
            </b-button>
          </td>
          <td>
            <b-button
              v-if="roomsetlabelChanged"
              class="btn-success"
              @click="saveRoomLabel"
              variant="primary"
              >{{$t('Save')}}</b-button
            >
          </td>
          <td/>
        </tr>
      </table>

    </b-card>
    <b-card  v-if="roomset" style="margin: 5px">
      <span style="font-weight: 800">{{ $t('Care Units features:') }}</span>
      <table class="isafeattab">
        <tr>
          <td>
            <span class="isalabel">{{ $t('Enable Close Follow Up:') }}</span>
          </td>
          <td>
            <label class="switch switch-primary mr-3"  @click="checkCloseFollowUpLicence">
              <input type="checkbox" v-model="local__featureCloseFollowUpEnabled" @change="roomsetfeatureChanged = true" :disabled="!roomset.licensing || !roomset.licensing.closeFollowUp"/>
              <span class="slider"/>
            </label>
            <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISACloseFollowUpHelp')" />
          </td>
        </tr>
        <tr>
          <td>
            <span class="isalabel">{{ $t('Enable Bed Exit:') }}</span>
          </td>
          <td>
            <label class="switch switch-primary mr-3" @click="checkBedExitLicence">
              <input type="checkbox" v-model="local__featureBedexitEnabled" @change="roomsetfeatureChanged = true" :disabled="!roomset.licensing || roomset.licensing.activeBedExitAllowed === 0"/>
              <span class="slider"/>
            </label>
            <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISABedExitHelp')"/>
          </td>
        </tr>
        <tr>
          <td>
            <span class="isalabel">{{ $t('Enable Weekly Report:') }}</span>
          </td>
          <td>
            <label class="switch switch-primary mr-3" @click="checkWeeklyReportLicence">
              <input type="checkbox" v-model="local__featureWeeklyReportEnabled" @change="roomsetfeatureChanged = true" :disabled="!roomset.licensing || !roomset.licensing.weeklyMailReporting"/>
              <span class="slider"/>
            </label>
            <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAWeeklyReportHelp')"/>
          </td>
          <td>
            <b-select
              v-if="local__featureWeeklyReportEnabled"
              v-model="local__weeklyReportDayOfWeek"
              :options="weeklyOptions"
              @change="roomsetfeatureChanged = true"
              />
          </td>
        </tr>
        <tr>
          <td>
            <span class="isalabel">{{ $t('Enable Monthly Report:') }}</span>
          </td>
          <td>
            <label class="switch switch-primary mr-3" @click="checkMonthlyReportLicence">
              <input type="checkbox" v-model="local__featureMonthlyReportEnabled" @change="roomsetfeatureChanged = true" :disabled="!roomset.licensing || !roomset.licensing.monthlyMailReporting"/>
              <span class="slider"/>
            </label>
            <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISAMonthlyReportHelp')"/>
          </td>
        </tr>
      </table>

      <b-button
        v-if="roomsetfeatureChanged"
        class="btn-success"
        @click="saveRoomLabel"
        variant="primary"
        >
        {{$t('Save')}}
      </b-button>
    </b-card>
    <b-card  v-if="roomset" style="margin: 5px">
      <span style="font-weight: 800">{{ $t('Licensing:') }}</span>
      <table class="isafeattab">
        <tr>
          <td>
            <span class="isalabel">{{ $t('Kiosk License usage:') }}</span>
          </td>
          <td>
            <span class="isalabel">{{ licencedKiosks  }}</span>
            <span class="isalabel"> / </span>
            <span class="isalabel" v-if="roomset.licensing && roomset.licensing.activeKioskAllowed === -1">{{$t('Unlimited')}}</span>
            <span class="isalabel" v-if="roomset.licensing && roomset.licensing.activeKioskAllowed >= 0">{{roomset.licensing.activeKioskAllowed}}</span>
            <span class="isalabel" v-if="!roomset.licensing">{{$t('No licencing')}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="isalabel">{{ $t('Fall Detection License usage:') }}</span>
          </td>
          <td>
            <span class="isalabel">{{ licencedFallDetection }}</span>
            <span class="isalabel"> / </span>
            <span class="isalabel" v-if="roomset.licensing && roomset.licensing.activeFallAllowed === -1">{{$t('Unlimited')}}</span>
            <span class="isalabel" v-if="roomset.licensing && roomset.licensing.activeFallAllowed >= 0">{{roomset.licensing.activeFallAllowed}}</span>
            <span class="isalabel" v-if="!roomset.licensing">{{$t('No licencing')}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="isalabel">{{ $t('Bed Exit License usage:') }}</span>
          </td>
          <td>
            <span class="isalabel">{{ licencedBedExitDetection  }}</span>
            <span class="isalabel"> / </span>
            <span class="isalabel" v-if="roomset.licensing && roomset.licensing.activeBedExitAllowed === -1">{{$t('Unlimited')}}</span>
            <span class="isalabel" v-if="roomset.licensing && roomset.licensing.activeBedExitAllowed >= 0">{{roomset.licensing.activeBedExitAllowed}}</span>
            <span class="isalabel" v-if="!roomset.licensing">{{$t('No licencing')}}</span>
          </td>
        </tr>
      </table>
    </b-card>

    <b-card  v-if="roomset" style="margin: 5px">
      <b-row>
        <b-col cols="4">
          <h4 v-if="roomset">
            {{ $t('Sensors') }}
          </h4>
        </b-col>
        <b-col cols="3">
          <b-button :loading="ordering" variant="secondary" float-right :styled="true" @click="prepareReorer(); reordering = !reordering" v-if="!$store.state.user.isMobile">
            {{ $t('Change dashboard order')}}
          </b-button>
        </b-col>
        <b-col cols="3">
          <b-button :loading="ordering" variant="primary" float-right :styled="true" @click="reorderRoom(roomset.roomList)" v-if="reordering">
            {{ $t('Save new room order')}}
          </b-button>
        </b-col>
        <b-col cols="2">
          <div>
            <label class="switch switch-primary mr-3" >
              <input type="checkbox" @click="displayAvanced = !displayAvanced"  />
              <span class="slider"/>
            </label>
              <span style="margin-left: -20px; margin-bottom">{{$t('Advanced View')}}</span>
          </div>
        </b-col>
      </b-row>

      <b-table
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        v-sortable="items"
        show-empty
        stacked="md"
        :items="JSON.parse(JSON.stringify(roomset.roomList)).sort((a, b) => (a.__NEW_dashboardPosition || a.dashboardPosition) === (b.__NEW_dashboardPosition || b.dashboardPosition) ? a.label.localeCompare(b.label) : (a.__NEW_dashboardPosition || a.dashboardPosition) - (b.__NEW_dashboardPosition || b.dashboardPosition))"
        :fields="fields"
        :current-page="currentPage"
        :per-page="roomset.roomList.length"
        :filter="filter"
        :filterIncludedFields="filterOn"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        v-if="roomset"
        :empty-text="this.$t('The list is empty.')"
        >
        <template v-slot:cell(selectpaper)="row">
          <span
            :id="'paper_' + row.item.id"
            :values="JSON.stringify(row.item)"
            ></span>
          <b-form-group>
            <input
            type="checkbox"
            @change="log"
            v-model="row.item.selectpaper"
            />
          </b-form-group>
        </template>
        <template v-slot:cell(moveDown)="row">
          <b-button
            role="button"
            class="tooltips m-auto"
            @click="$store.commit('fleet/SET_ROOM_POSITION', { oid: row.item.oid, dashboardPosition: row.item.__NEW_dashboardPosition + 1.5 }); prepareReorer(true)"
            v-if="row.index < roomset.roomList.length-1"
            >
            <font-awesome-icon icon="arrow-down" />
          </b-button>
        </template>
        <template v-slot:cell(moveUp)="row">
          <b-button
            role="button"
            class="tooltips m-auto"
            @click="$store.commit('fleet/SET_ROOM_POSITION', { oid: row.item.oid, dashboardPosition: row.item.__NEW_dashboardPosition - 1.5 }); prepareReorer(true)"
            v-if="row.index > 0"
            >
            <font-awesome-icon icon="arrow-up" />
          </b-button>
        </template>

        <template v-slot:cell(dashboardPosition)="row">
          <font-awesome-icon class="grabbable" icon="grip-lines-vertical" style="" />
        </template>

        <template v-slot:cell(label)="row">
          <span v-if="row.item.label.startsWith('_') && !isMintTAdmin()" style="font-size: 1.1rem;">
            {{ row.item.label.substring(1)}}
          </span>
          <span v-else style="font-size: 1.1rem;">
            {{ row.item.label}}
          </span>
          <span v-if="row.item.label.startsWith('_')" style="font-size: 0.9rem; color: #757575;">
            {{ $t('In Setup')}}
            <font-awesome-icon icon="question-circle" @click="$store.commit('user/NEEDS_HELP','ISASensorSetup')"/>
          </span>
        </template>

        <template v-slot:cell(edit)="row">
          <b-dropdown  size="lg" dropleft  variant="link" toggle-class="text-decoration-none" no-caret class="p-2" >
            <template #button-content>
              <font-awesome-icon icon="ellipsis-v" />
            </template>
            <b-dropdown-item class="p-2" @click="editSensor(row.item)"> <font-awesome-icon icon="wrench" />
              {{ $t('Edit...') }}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group id="dropdown-group-1" :header="$t('Move Sensor')">
              <template v-for="rs in $store.state.fleet.roomsetList.filter((p) => p.organizationLabel === $store.state.fleet.currentOrganization && p.label !== roomset.label && p.label.charAt(0) !== '_')">
                <b-dropdown-item-button :key="rs.label" @click="sensorMove(row.item, rs.oid, rs.roomList)">
                  {{$t('Move Sensor to') + ' ' + rs.label }}
                </b-dropdown-item-button>
              </template>
            </b-dropdown-group>
          </b-dropdown>
        </template>

        <template v-slot:cell(sensor.isOnline)="row">
          <span
            v-if="row.item.sensor.isOnline >= 0"
            style="color: #36958d"
            >
            {{ $t('Online') }}
          </span>
          <span
            v-if="row.item.sensor.isOnline === -1"
            style="color: #880000"
            >{{ $t('Offline') }}
            <a
              role="button"
              class="tooltips m-auto"
              @click="askForHelp"
              >
              <font-awesome-icon icon="question-circle" />
            </a>
          </span>
            <!--
              <font-awesome-icon
              icon="check"
              v-if="row.item.sensor.isOnline === 1"
              style="color: #36958d"
              />
              <font-awesome-icon
              icon="check-circle"
              v-if="row.item.sensor.isOnline === 0"
              style="color: orange"
              />
              <font-awesome-icon
              icon="arrow-down"
              v-if="row.item.sensor.isOnline === -1"
              style="color: red"
              />-->
        </template>
        <template v-slot:cell(sensor.settings.enabled)="row">
          <label class="switch switch-primary mr-3">
            <input v-if="!row.item.label.startsWith('_')" type="checkbox" :checked="row.item.sensor.settings.enabled" @click.prevent="switchEnable(row.item, $event)" />
            <input v-else type="checkbox" :checked="false" disabled />
            <span class="slider"></span>
          </label>
        </template>
        <template v-slot:cell(sensor.settings.enableBedExitDetection)="row">
          <div  class="d-flex">
            <label class="switch switch-primary mr-3">
              <input v-if="!row.item.label.startsWith('_')" type="checkbox" :checked="row.item.sensor.settings.enableBedExitDetection" @click.prevent="switchBedExitEnable(row.item, $event) "  />
              <input v-else type="checkbox" :checked="false" disabled />
              <span class="slider"></span>
            </label>

            <span v-if="row.item.sensor.settings.enableBedExitDetection && row.item.sensor.settings.bedExitTimeRanges.length > 0" class="isalabel">
              {{ $t('From:') }} {{ formatTwo(row.item.sensor.settings.bedExitTimeRanges[0].startHourLocalTime) +'h'+ formatTwo(row.item.sensor.settings.bedExitTimeRanges[0].startMinuteLocalTime) }} {{ $t('To:') }} {{ formatTwo(row.item.sensor.settings.bedExitTimeRanges[0].endHourLocalTime) +'h'+ formatTwo(row.item.sensor.settings.bedExitTimeRanges[0].endMinuteLocalTime) }}
            </span>
          </div>
        </template>

        <template v-slot:cell(sensorConnectivity)="row">
          <div  class="d-flex">
            <span v-if="row.item.userSensor.defaultRoutingDevice === 'wlan0'"  class="isalabel">Wifi {{row.item.userSensor.wanSsid}} - {{row.item.userSensor.wanSignal}}%</span>
            <span v-if="row.item.userSensor.defaultRoutingDevice === 'eth0'"  class="isalabel">Ethernet</span>
            <span v-if="row.item.userSensor.defaultRoutingDevice === 'ppp0'"  class="isalabel">4G</span>
          </div>
        </template>

        <template v-slot:cell(networkLatency)="row">
          <div  class="d-flex">
            <span v-if="row.item.userSensor.defaultRoutingDevice === 'wlan0'"  class="isalabel">{{(1000*row.item.userSensor.responseTimeWlan).toFixed(2)}}ms</span>
            <span v-if="row.item.userSensor.defaultRoutingDevice === 'eth0'"  class="isalabel">{{(1000*row.item.userSensor.responseTimeLan).toFixed(2)}}ms</span>
            <span v-if="row.item.userSensor.defaultRoutingDevice === 'ppp0'"  class="isalabel">{{(1000*row.item.userSensor.responseTime4g).toFixed(2)}}ms</span>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { SPService } from "@/services/sp.service";
import momenttz from "moment-timezone";
import { API_URL } from '@/.env'
import ROLES from "@/constants/roles";
import { extend } from 'vee-validate';

extend('notUnderscore', {
  params: ['isMinttAdmin', 'message'],
  validate(value, { isMinttAdmin }) {
    if ( !isMinttAdmin ) {
      return !value.startsWith('_');
    } else {
      return true;
    }
  },
  message: (_, values) => values.message
});

export default {
  components: {  },
  name: "RoomsetEdition",
  props: {
    roomset: {
      type: Object,
      required: true,
       default: () => ({})
    },
  },
  data() {
    return {
      reordering: false,
      roomsetlabelChanged: false,
      roomsetfeatureChanged: false,
      roomsetName: "",
      enableCloseFollowUp: true,
      enableBedExit: true,
      rankOption: "default",
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      sortBy: "paper",
      sortDesc: false,
      sortableOptions: {
      chosenClass: "is-selected",
      },
      perPage:
        this.results_per_page === "Todo"
          ? this.items.length
          : this.results_per_page
          ? this.results_per_page
          : 50,
      pageOptions: [10, 50, 100, 500, "Todo"],
      sortDirection: "asc",
      selectedRoomsetOid: null,
      selectedRoomsetId: 0,
      dirtyReordering: false,
      ordering: false,
      items: [],
      displayAvanced: false,

      local__label: '',
      local__timezone: '',
      local__featureCloseFollowUpEnabled: false,
      local__featureBedexitEnabled: false,
      local__featureMonthlyReportEnabled: false,
      local__featureWeeklyReportEnabled: false,
      local__weeklyReportDayOfWeek: 0,
      local__featureYearlyReportEnabled: false,
    };
  },
  directives: {
    sortable: {
      bind(el, binding, vnode) {
        let self = el;
        Sortable.create(el.querySelector("tbody"), {
          ...binding.value,
          vnode: vnode,
          onEnd: (e) => {
            let ids = el.querySelectorAll("button");
            let order = [];
            for (let i = 0; i < ids.length; i++) {
              let oid = ids[i].getAttribute("id");
              //extract items checkbox onChange v-model
              vnode.context.$store.commit("fleet/SET_ROOM_POSITION", {
                oid,
                dashboardPosition: i + 1.5,
              });
            }
            vnode.context.dirtyReordering = true;
            this.prepareReorer()
            // console.table(vnode.context.items)
          },
        });
      },
    },
  },
  computed: {
    /*displayAvanced: {
      get: function() { return this._displayAvanced;},
      set: function(val) { this._displayAvanced = val; }
    },*/
    validationRules() {
      let rules = {
        required: true,
        min: 1,
        max: 50,
        notUnderscore: {
          isMinttAdmin: this.$store.state.user.currentUser && this.$store.state.user.currentUser.role === ROLES.MINTT_ADMIN,
          message: this.$t('Care Unit Name cannot start with an underscore')
        }
      };
      return rules;
    },

    licencedKiosks() {
      return this.$store.state.fleet.users.filter( u =>u.organizationLabel === this.roomset.organizationLabel && u.role === 0 && !u.deleted).length
    },

    licencedFallDetection() {
      let total = 0;
      for(let rs of this.$store.state.fleet.roomsetList.filter(tt => tt.organizationLabel === this.roomset.organizationLabel && tt.label.charAt(0) !== '_')) {
        total += rs.roomList.filter(r => r.sensor.settings.enabled).length
      }
      return total;
    },

    licencedBedExitDetection() {
      let total = 0;
      for(let rs of this.$store.state.fleet.roomsetList.filter(tt => tt.organizationLabel === this.roomset.organizationLabel && tt.label.charAt(0) !== '_')) {
        total += rs.roomList.filter(r => r.sensor.settings.enableBedExitDetection).length
      }
      return total;

    },

    timezones() {
      return momenttz.tz.names();
    },

    timezone: {
      get: function() {
        if(!this._timezone) this.timezone = this.roomset.timezone;
        return this._timezone;
      },
      set: function(value) {this._timezone = value; }
    },

    weeklyOptions() {
      return [
        { text: this.$t('monday 7am (from thursday j-11 to thursday j-4)'), value: 70},
        { text: this.$t('monday 15pm (from sunday j-8 to sunday j-1)'), value: 30},
        { text: this.$t('tuesday 7am (from sunday j-9 to sunday j-2)'), value: 71},
        { text: this.$t('tuesday 15pm (from monday j-8 to monday j-1)'), value: 31},
        { text: this.$t('wednesday 15pm (from tuesday j-8 to tuesday j-1)'), value: 32},
        { text: this.$t('wednesday 7am (from monday j-9 to monday j-2)'), value: 72},
        { text: this.$t('thursday 7am (from tuesday j-9 to tuesday j-2)'), value: 73},
        { text: this.$t('thursday 15pm (from wednesday j-8 to wednesday j-1)'), value: 33},
        { text: this.$t('friday 7am (from wednesday j-9 to wednesday j-2)'), value: 74},
        { text: this.$t('friday 15pm (from thursday j-8 to thursday j-1)'), value: 34},
        { text: this.$t('saturday 7am (from thursday j-9 to thursday j-2)'), value: 75},
        { text: this.$t('saturday 15pm (from thursday j-9 to thursday j-2)'), value: 35},
        { text: this.$t('sunday 7am (from thursday j-10 to thursday j-3)'), value: 76},
        { text: this.$t('sunday 15pm (from thursday j-10 to thursday j-3)'), value: 36},
      ];
    },

    fields() {
      let f = [];
      if (this.reordering)
      {
        // f.push({ key: "dashboardPosition", label: this.$t("Position"), sortable: false });
        f.push({ key: "moveUp", label: this.$t("Up"), sortable: false });
        f.push({ key: "moveDown", label: this.$t("Down"), sortable: false });
      }
      f.push({ key: "label", label: this.$t("Room"), sortable: false });
      f.push({ key: "edit", label: this.$t("Edit") });
      if(this.displayAvanced)
        f.push({ key: "externalID", label: this.$t("Sensor Hostname"), sortable: false })
      if(this.displayAvanced)
        f.push({
          key: "userSensor.macAddress",
          label: this.$t("Sensor Mac LAN"),
          sortable: false,
        });
      if(this.displayAvanced)
        f.push({
          key: "userSensor.macAddressWan",
          label: this.$t("Sensor mac WLAN"),
          sortable: false,
        });

      f.push({
        key: "userSensor.serialNumber",
        label: this.$t("Sensor Serial Number"),
        sortable: false,
      })
      if(this.displayAvanced)
        f.push({ key: "sensor.isOnline", label: this.$t("Connected"), sortable: false });

      if(this.displayAvanced) f.push({ key: "sensorConnectivity", label: this.$t("Network"), sortable: false });
      if(this.displayAvanced) f.push({ key: "networkLatency", label: this.$t("API Latency"), sortable: false });

      if(this.$store.state.user.currentUser.role === 5 || this.$store.state.user.currentUser.role === 11) {
        f.push({ key: "sensor.settings.enabled", label: this.$t("Fall Detection Enabled"), sortable: false });
        f.push({ key: "sensor.settings.enableBedExitDetection", label: this.$t("Bed Exit Detection Enabled"), sortable: false });
      }
      return f;
    },

    customOrderingMode() {
      if (this.roomset && this.roomset.roomList) {
        let positions = [];
        for (let r of this.roomset.roomList) {
          if (positions.find((p) => p === r.dashboardPosition) == null)
            positions.push(r.dashboardPosition);
        }
        if (positions.length === this.roomset.roomList.length)
          // one position per room --> full manual
        return 2;

        if (positions.length === 1)
          // one position per rool --> full auto
        return 0;

        if (positions.length === this.roomset.roomList.length)
          // less positions per room --> not full manual
        return 1;
      }
      return false;
    },
  },

  
  watch: {
    roomset: function(value) {
        this.initWithRoomset(value);
    }
  },


  methods: {
    getValidationState({ dirty, validated, valid }) {
      return dirty || validated ? valid : null;
    },

    formatTwo(a) {
      return a.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
    },

    askForHelp() {
      this.$store.commit("user/NEEDS_HELP", "sensor-offline");
    },

    askForHelpRoom() {
      this.$store.commit("user/NEEDS_HELP", "ISASensorSetup");
    },

    deleteRoomset() {
      console.log('delete roomset')
      this.$store.commit('user/SHOW_ROOMSETDELETOR', this.roomset);
    },

    roomsetSelect(oid) {
      console.error('roomset changed to ' + oid)
      if(oid)
        this.selectedRoomsetOid = oid;
      const ts = this.$store.state.fleet.roomsetList.find(
        (r) => r.oid === oid ? oid :  this.selectedRoomsetOid
      );
      if (!ts) {
        this.roomsetName = "";
        this.timezone = "";
        this.items = [];
        return;
      }
      this.roomsetName = ts.label;
      this.timezone = ts.timezone;
      this.enableCloseFollowUp = ts.featureCloseFollowUpEnabled || true;
      this.enableBedExit = ts.featureBedexitEnabled || false;

      const rl = ts.roomList;
      this.items = rl.sort((a, b) =>
        a.dashboardPosition === b.dashboardPosition
        ? a.label.localeCompare(b.label)
        : a.dashboardPosition - b.dashboardPosition
      );
    },

    async saveRoomLabel() {
      const nrs = {
        oid: this.roomset.oid,
        label: this.local__label,
        timezone: this.local__timezone,
        featureCloseFollowUpEnabled: this.local__featureCloseFollowUpEnabled,
        featureBedexitEnabled: this.local__featureBedexitEnabled,
        featureMonthlyReportEnabled: this.local__featureMonthlyReportEnabled,
        featureWeeklyReportEnabled: this.local__featureWeeklyReportEnabled,
        weeklyReportDayOfWeek: this.local__weeklyReportDayOfWeek,
        featureYearlyReportEnabled: this.local__featureYearlyReportEnabled,
      };
      const result = await SPService.updateRoomset(
        API_URL.url,
        this.$store.state.user.currentUser.jwt,
        nrs
      );
      if (result.status === 200) {
        this.roomsetlabelChanged = false;
        this.roomsetfeatureChanged = false;
        this.$bvToast.toast(this.$t('The roomset has been updated with success'), {
          title: this.$t('Roomset edition'),
          solid: true,
          variant: 'success',
          duration: 5000
        });
      } else {
        this.$bvToast.toast(this.$t('Error while updating the roomset') + `\n${result.status}: ${result.statusMessage}`, {
          title: this.$t('Roomset edition'),
          solid: true,
          variant: 'warning',
          duration: 5000
        });
      }
    },

    async resetOrdering() {
      this.ordering = true;
      setTimeout(() => {
        this.ordering = false;
      }, 3000);
    },

    async reorderRoom(rl) {
      this.ordering = true;

      for (const r of rl) {
        if(r.__NEW_dashboardPosition &&  r.__NEW_dashboardPosition !== r.dashboardPosition)
        {
          console.log("moveSensor: reorderRoom")
          await SPService.updateRoom(
            API_URL.url,
            this.$store.state.user.currentUser.jwt,
            {
              oid: r.oid,
              dashboardPosition: r.__NEW_dashboardPosition || r.dashboardPosition,
            }
          );
        }
      }
      this.dirtyReordering = false;
      this.ordering = false;
      this.reordering = false;
    },

    prepareReorer(force) {
      let index = 1;
         for(let room of this.roomset.roomList.sort((a, b) => (a.__NEW_dashboardPosition || a.dashboardPosition) === (b.__NEW_dashboardPosition || b.dashboardPosition) ? a.label.localeCompare(b.label) : (a.__NEW_dashboardPosition || a.dashboardPosition) - (b.__NEW_dashboardPosition || b.dashboardPosition)))
         {

           this.$store.commit("fleet/SET_ROOM_POSITION", {
                oid: room.oid,
                dashboardPosition: index,
              });
            index++;
         }
         this.dirtyReordering = true;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    editSensor(s) {
      this.$store.commit("user/SHOW_ROOMEDITOR", s);
    },

    sensorMove(room, rsoid, roomList) {
      let dashboard = roomList.length + 1;
      this.$store.dispatch("fleet/moveSensor", { room, rsoid, dashboard});
    },

    switchEnable(room, ev) {
      if(ev.target.checked) {
        this.$store.dispatch("fleet/enableSensor", room);
      }
      else {
        this.$store.dispatch("fleet/disableSensor", room);

        if(room.sensor.settings.enableBedExitDetection)   {
          this.$store.dispatch('fleet/disableBedExit', room.oid);
        }
      }
      let text = this.$t('The sensor {room} is now {status}', { room: room.label, status: ev.target.checked ? this.$t('enabled') : this.$t('disabled')})
      this.$bvToast.toast(text, {
        title: this.$t('Roomset Management'),
        solid: true,
        variant: 'success',
        duration: 5000
      });
    },

    switchBedExitEnable(room, ev) {
      this.$store.commit('user/SHOW_BEDEXITSETTINGS', room)
    },

    initWithRoomset(value) {
      if (!this.roomsetlabelChanged)
      {
        this.local__label = value.label;
        this.local__timezone = value.timezone;
      }
      if(!this.roomsetfeatureChanged)
      {
        this.local__featureCloseFollowUpEnabled = value.featureCloseFollowUpEnabled;
        this.local__featureBedexitEnabled = value.featureBedexitEnabled;
        this.local__featureMonthlyReportEnabled = value.featureMonthlyReportEnabled;
        this.local__featureWeeklyReportEnabled = value.featureWeeklyReportEnabled;
        this.local__weeklyReportDayOfWeek = value.weeklyReportDayOfWeek;
        this.local__featureYearlyReportEnabled = value.featureYearlyReportEnabled;
      }
    },

    checkCloseFollowUpLicence() {
      if(!this.roomset.licensing || !this.roomset.licensing.closeFollowUp) {
        this.$store.commit('user/NEEDS_HELP','ISACloseFollowUpLicense');
      }
    },

    checkBedExitLicence() {
      if(!this.roomset.licensing || this.roomset.licensing.activeBedExitAllowed === 0) {
        this.$store.commit('user/NEEDS_HELP','ISABedExitLicense');
      }
    },
    
    checkWeeklyReportLicence() {
      if(!this.roomset.licensing || !this.roomset.licensing.weeklyMailReporting) {
        this.$store.commit('user/NEEDS_HELP','ISAWeeklyReportLicense');
      }
    },

    checkMonthlyReportLicence() {
      if(!this.roomset.licensing || !this.roomset.licensing.monthlyMailReporting) {
        this.$store.commit('user/NEEDS_HELP','ISAMonthlyReportLicense');
      }
    },

    hasDuplicateDashboard(roomset) {
      const seenPositions = {};
      for (const room of roomset.roomList) {
        if (seenPositions[room.dashboardPosition]) {
          return true;
        } else {
          seenPositions[room.dashboardPosition] = true;
        }
      }
      return false;
    },

    hasMissingDashboardPositions(roomset) {
      const sortedRooms = roomset.roomList.slice().sort((a, b) => a.dashboardPosition - b.dashboardPosition);
      let expectedPosition = 1;
      for (const room of sortedRooms) {
        const currentDashboardPosition = room.dashboardPosition;
        if (currentDashboardPosition !== expectedPosition) {
          return true;
        }
        expectedPosition++;
      }
      return false;
    },

    isMintTAdmin() {
      return this.$store.state.user.currentUser.role === ROLES.adminMintt
    },
  },
  mounted() {
    if (this.hasDuplicateDashboard(this.roomset))
    {
      this.roomset.roomList.sort((a, b) => (a.label > b.label ? 1 : -1));
      this.roomset.roomList.forEach((room, index) => {
        room.dashboardPosition = index + 1;
        SPService.updateRoom(
          API_URL.url,
          this.$store.state.user.currentUser.jwt,
          {
            oid: room.oid,
            dashboardPosition: room.__NEW_dashboardPosition || room.dashboardPosition,
          }
        );
      });
    } else if (this.hasMissingDashboardPositions(this.roomset)) {
      this.roomset.roomList.sort((a, b) => a.dashboardPosition - b.dashboardPosition);
      let nextNumber = 1;
      this.roomset.roomList.forEach((room) => {
        if (room.dashboardPosition != nextNumber)
        {
          room.dashboardPosition = nextNumber;
          SPService.updateRoom(
            API_URL.url,
            this.$store.state.user.currentUser.jwt,
            {
              oid: room.oid,
              dashboardPosition: nextNumber,
            }
          );
        }
        nextNumber += 1;
      });
    }
    this.initWithRoomset(this.roomset);
  },
}
</script>

<style lang="scss" scoped>
  .isalabel {
      font-size: 15px;
      color: #222121;
      margin-bottom: 4px;
      padding-right: 1rem;
  }

  .grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  .isafeattab  {
    border-collapse:separate;
    border-spacing:1em;
  }

  .switch {
    top: 2px;
  }
</style>
